<!--  -->
<template>
  <div class="centerWrapper">
    <img
      src="../../assets/logo.png"
      style="width:6rem;height:6rem;margin-top:20px"
    />
    <div style="color:#9D9FA1;font-size:.7rem;margin:1rem 0">
      请选择界面：
    </div>
    <div v-if="pageList[1] && pageList[1]['subPermission']" style="width:100%">
      <div v-for="item in pageList[1]['subPermission']" :key="item.id">
        <div
          class="singleRoleOption"
          @click="handleSelectPage(item)"
          v-if="item.permissionType"
          style="margin-bottom: 10px"
        >
          {{ item.permissionName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {
    pageList() {
      return this.$store.state.user.pageList
        ? JSON.parse(this.$store.state.user.pageList)
        : [];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleSelectPage(item) {
      this.$store.commit("SetIsAdmin", item.permissionName === "管理端");
      this.$store.commit("SetNavBarTitle", "首页");
      this.$store.commit("SetAuthority", JSON.stringify(item.subPermission));
      this.$router.push({ path: "/index" });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
</style>
